import React from "react"
import { Link } from "gatsby"

function Project(props) {

  return (
    <Link to={props.link} className="project">
      <img src={props.image} className="project__image" alt="" />
      <div className="project__name">{props.name}</div>
    </Link>
  )
}

export default Project
