import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Project from "../components/project"

const IndexPage = () => (
  <Layout>
    <SEO title="Work" />
    <main className="work">
      <h1 className="visually-hidden">Work</h1>
      <Project link="/art-van-cart/" image="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584642287/hireallisonokeefe/work/work-art-van-cart_bqzt1b.jpg" name="Case Study: Art Van Cart" />
      <Project link="/art-van-checkout/" image="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584664401/hireallisonokeefe/work/work-art-van-checkout_rqbeny.jpg" name="Case Study: Art Van Checkout" />
      <Project link="/lake-huron-lodge/" image="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584642287/hireallisonokeefe/work/work-lake-huron-lodge_tf2oj2.jpg" name="UI Redesign: Lake Huron Lodge" />
      <Project link="/my-sofa-custom-sofa-builder/" image="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584642287/hireallisonokeefe/work/work-my-sofa-custom-sofa-builder_vsur3i.jpg" name="Prototype: My Sofa Custom Sofa Builder" />
      <Project link="/email-templates/" image="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584642287/hireallisonokeefe/work/work-email-templates_ttsnqp.jpg" name="UI/UX: Email Templates" />
      <Project link="/puresleep-homepage/" image="https://res.cloudinary.com/innocent/image/upload/f_auto/v1584642288/hireallisonokeefe/work/work-puresleep-homepage_z8iwov.jpg" name="UI/UX Redesign: PureSleep Homepage" />
    </main>
  </Layout>
)

export default IndexPage
